






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Loading } from 'element-ui';
import $ from 'jquery';

@Component({
  components: {
    InsCmsPersonSwiper: () => import('@/components/business/cms/InsCmsPersonSwiper.vue')
  }
})

export default class InsCmsCat1 extends Vue {
  page: number = 1; // 当前頁
  pageSize: number = 3; // 顯示文章數
  contentList: object[] = []; // cms内容列表
  showPersonList: boolean = false;
  showCatList: boolean = false;
  personIndex: number = 0;
  curScrollTop: number = 0;
  timeout:any = null;
  totalPage: number = 0;

  // 获取 Category 和所有 SubCategory 的 cms 列表
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, Page: this.page, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        if (result.Data && result.Data.length) {
          this.totalPage = result.TotalPage;
          this.contentList = this.contentList.concat(result.Data);
          this.$nextTick(() => {
            $('.man-list li').fadeIn(1000);
          });
          console.log(this.contentList, ' 获取人物列表');
          this.lazyLoad();
        }
      });
  }
  lazyLoad () {
    $(window).scroll(() => {
      let sTop:any = $(window).scrollTop();
      let winH = $(window).height();
      let docH = Number($('#container').height()) + Number($('#header').height());
      if (sTop + winH >= docH && this.page < this.totalPage) {
        if (this.timeout != null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.page += 1;
          this.getSubCatContents();
        }, 50);
      }
    });
  }
  // 点击某个人物
  clickOne (i) {
    this.showPersonList = true;
    this.personIndex = i;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    this.curScrollTop = -1 * scrollTop;
    $('body').css({ 'position': 'fixed', 'width': '100%', 'top': this.curScrollTop + 'px', 'overflow-y': 'hidden' });
  }

  showSwiper (sta) {
    this.showPersonList = sta;
    $('body').css({ 'position': 'static', 'overflow-y': 'scroll' });
    $(window).scrollTop(-1 * this.curScrollTop);
  }

  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  mounted () {
    this.getSubCatContents();
  }
}
